import {createGlobalStyle} from 'styled-components'

import { fontSize, media, lineHeight, color, space, font } from './constants'

// TODO Create a helper function to convert colors to RGB and HSL and use them here as well

/*
Abstract fonts from the visual design of Portfolio v2
*/

const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
      box-sizing: border-box;
    }

    root{
        // Keep it as 16
        font-size: 16px;
    }

    html {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
        scroll-behavior: smooth;
    }

    div {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    div::-webkit-scrollbar {
      display: none;
    }
    *:focus {
        outline: 0;
    }
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${color.base50};
        margin: 0;
    }
    main{
        background-color: transparent;
        color: ${color.base800};
    }
    img {
        border-style: none;
        display: block;
        width: 100%;
      }
    h1{
        ${media.xs`
          font-size: ${fontSize.text7Xl}; 
          line-height: ${lineHeight.text7Xl};
        `}
        ${media.lg`
          font-size: ${fontSize.text8Xl}; 
          line-height: ${lineHeight.text8Xl};
        `}
    }
    h2{
        ${media.xs`
          font-size: ${fontSize.text5Xl};
          line-height: ${lineHeight.text5Xl};
        `}
        ${media.xl`
          font-size: ${fontSize.text6Xl};
          line-height: ${lineHeight.text6Xl};
        `}
    }
    h3{
        margin: 0;
        ${media.xs`
          font-size: ${fontSize.text3Xl}; 
          line-height: ${lineHeight.text3Xl};
        `}
        ${media.lg`
          font-size: ${fontSize.text4Xl};
          line-height: ${lineHeight.text4Xl}; 
        `}
        ${media.xl`
          font-size: ${fontSize.text5Xl};
          line-height: ${lineHeight.text5Xl}; 
        `}
    }
    h4{
        margin: 0;
        ${media.xs`
          font-size: ${fontSize.text2Xl}; 
          line-height: ${lineHeight.text2Xl};
        `}
        ${media.lg`
          font-size: ${fontSize.text3Xl};
          line-height: ${lineHeight.text3Xl}; 
        `}
    }

    p, ol, li {
        margin: ${space.p100} 0;
        ${media.xs`
        font-size: ${fontSize.textXl}; 
        line-height: ${lineHeight.textXl};
        `}
        ${media.md`
        font-size: ${fontSize.textXl};
        line-height: ${lineHeight.textXl};
        `}
    }
    caption {
        margin: ${lineHeight.textBase} 0;
        font-size: ${fontSize.textBase}; 
        line-height: ${lineHeight.textBase};
    }
    h1, h3, h4 {
        text-decoration: none;
        font-family: ${font.text};
    }
    p, h2, li, a, button, caption {
        text-decoration: none;
        font-family: ${font.text};
    }
    code {
        font-family: ${font.code};
    }
    p > code,
    li > code,
    dd > code,
    
    // Review this bit
    td > code {
      background: #ffeff0;
      color: ${color.blue400};
      word-wrap: break-word;
      box-decoration-break: clone;
      padding: .1rem .3rem .2rem;
      border-radius: .2rem;
    }
    pre code {
      display: block;
      background: ${color.lightGrey};
      border-left: ${color.acBgBlue} 2px solid;
      white-space: pre;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      max-width: 100%;
      min-width: 100px;
      padding: 0;
}
    @keyframes shimmer-bg{
      0%{
          background-position: left;
      }
      100%{
          background-position: right;
      }
    }
`
export default GlobalStyles
