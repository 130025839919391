// Here's where the head may need to change

import React from 'react'
import Head from '../Head'
import GlobalStyles from '../../styles/global'

const Layout = ({ children, title, lottie }) => {
    return (
        <React.Fragment>
            <Head title={title} lottie={lottie}/>
            <GlobalStyles />
            <main>
                {children}
            </main>
        </React.Fragment>
    )
}

export default Layout