import { css } from 'styled-components'

// TODO Trim ready
export const color = {
  // Base → Slate (Tailwind)
  base50: '#f8fafc',
  base100: '#f1f5f9',
  base200: '#e2e8f0',
  base300: '#cbd5e1',
  base400: '#94a3b8',
  base500: '#64748b',
  base600: '#475569',
  base700: '#334155',
  base800: '#1e293b',
  base900: '#0f172a',

  // Links (cyan600)
  link: '#0E7490',
  visited: '#4c1d95',
  // link: #10899e → pulled from Evergreen
  
  // Pastel semantic backgrounds (Pulled from Evergreen)
  sbGreen: '#d2f2ea',
  sbBlue: '#d6e0ff',
  sbYellow: '#ffefd2',
  sbRed: '#f4b6b6',

  // Emphasis (pink700)
  emph: '#be185d',

  // Monochrome (Gray in Tailwind)
  white: '#FFFFFF',
  black: '#000000',
  grey50: '#f9fafb',
  grey100: '#f3f4f6',
  grey200: '#e5e7eb',
  grey300: '#d1d5db',
  grey400: '#9ca3af',
  grey500: '#6b7280',
  grey600: '#4b5563',
  grey700: '#374151',
  grey800: '#1f2937',
  grey900: '#111827',

  // TODO Retire all colors below this line
  /* Blues */
  blue100: '#4F65F1',
  blue200: '#423CF4',
  blue300: '#3C38E3',
  blue400: '#2A336B',
  blue500: '#0A1246',
  /* Accents */
  acYellow: '#FFDA28',
  acRed: '#FF588E',
  acGreen: '#2CF8C3',
  acBlue: '#21CAFE',
  /* Accented BGs */
  acBgYellow: '#FEDE63',
  acBgRed: '#FF7B98',
  acBgGreen: '#51E8BF',
  acBgBlue: '#30B1FF',
  /* Darkened BGs */
  acDarkGreen: '#16B2A8',
  acDarkRed: '#7A105C',
  /* Shades */
  white: '#FFFFFF',
  lightGrey: '#E6E6E6',
  black: '#000000'
}

// TODO Use a function instead and retire this one
export const colorRgb = {
  /* Blues */
    blue100: '79, 101, 241',
    blue200: '66, 60, 244',
    blue300: '60, 56, 227',
    blue400: '42, 51, 107',
    blue500: '10, 18, 70',

    // radial-gradient(at 16% 63%, hsla(164,77%,62%,1) 0, transparent 52%),  
    // radial-gradient(at 95% 74%, hsla(203,100%,60%,1) 0, transparent 41%),  
    // radial-gradient(at 29% 33%, hsla(48,99%,70%,1) 0, transparent 70%),

  /* Accents */
    acYellow: '255, 218, 40',
    acRed: '255, 88, 142',
    acGreen: '44, 248, 195',
    acBlue: '33, 202, 254',

  /* Accented BGs */
    acBgYellow: '254, 222, 99',
    acBgRed: '255, 123, 152',
    acBgGreen: '81, 232, 191',
    acBgBlue: '48, 177, 255',
  
  /* Shades */
    white: '255, 255, 255',
    lightGrey: '230, 230, 230',
    black: '0, 0, 0'
}

export const font = {
  /* Add fonts here */
  display: 'Playfair Display, serif',
  text: 'IBM Plex Sans, sans-serif',
  code: 'IBM Plex Mono, monospace'
}

export const fontWeight = {
  // Only valid for 9-weight fonts like Inter, IBM Plex Sans
  'thin': '100',
  'extralight': '200',
  'light': '300',
  'normal': '400',
  'medium': '500',
  'semibold': '600',
  'bold': '700',
  'extrabold': '800',
  'black': '900'
}

export const fontSize = {
  // Radix: 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96, 128
  textXs: '0.75rem',
  textSm: '0.875rem',
  textBase: '1rem',
  textLg: '1.125rem',
  textXl: '1.25rem',
  text2Xl: '1.5rem',
  text3Xl: '1.875rem',
  text4Xl: '2.25rem',
  text5Xl: '3rem',
  text6Xl: '3.75rem',
  text7Xl: '4.5rem',
  text8Xl: '6rem',
  text9Xl: '8rem'
}

export const lineHeight = {
  // Radix: 16, 20, 24, 28, 28, 32, 36, 40, 48, 60, 72, 96, 128

  // Line height comparison
  // Radix fonts: 12, 14,        16, 18, 20, 24, 30, 36, 48, 60, 72, 96, 128
  //   Evergreen: 16, (24h, 20), 24, 24, 24, 32, 40
  textXs: '1rem',
  textSm: '1.25rem',
  textBase: '1.5rem',
  textLg: '1.75rem',
  textXl: '1.75rem',
  text2Xl: '2rem',
  text3Xl: '2.25rem',
  text4Xl: '2.5rem',
  text5Xl: '3rem',
  text6Xl: '3.75rem',
  text7Xl: '4.5rem',
  text8Xl: '6rem',
  text9Xl: '8rem'
}

export const grid = {
  xsGap: '2rem',
  smGap: '2rem',
  mdGap: '2rem',
  lgGap: '2rem',
  xlGap: '2rem',

  xsMargin: '1rem',
  smMargin: '1rem',
  mdMargin: '3rem',
  lgMargin: '4rem',
  xlMargin: '4rem'
  
}

export const space = {
  /* Global margins */
  p500: '5rem',
  p400: '4rem',
  p300: '3rem',
  p200: '2rem',
  p150: '1.5rem',
  p100: '1rem',
  p75: '0.75rem',
  p50: '.5rem',
  p25: '.25rem',

  /* Container margins */
  xsMargin: '1rem',
  smMargin: '1rem',
  mdMargin: '1.5rem',
  lgMargin: '1.5rem',
  xlMargin: 'auto'
}

export const measures = {
  /* Equivalent to 1280px (Macbook 13-inch resolution) */
  maxWidth: '80rem'
}

export const mq = {
  /*
    xxs: <240
    xs: >=320(20rem) or 240(15rem)?
    sm: >=480
    md: >=768
    lg: >=1024
    xl: >=1440
  */
  xs: '15rem',
  sm: '30rem',
  md: '48rem',
  lg: '64rem',
  xl: '90rem'
}

export const media = {
    // don't like xxs one bit
    xxs: (...a) => css`
      @media only screen and (max-width: ${mq.xs}) {
        ${css(...a)}
    }
    `,
    xs: (...a) => css`
      @media only screen and (min-width: ${mq.xs}) {
        ${css(...a)}
      }
    `,
    sm: (...a) => css`
      @media only screen and (min-width: ${mq.sm}) {
        ${css(...a)}
      }
    `,
    md: (...a) => css`
      @media only screen and (min-width: ${mq.md}) {
        ${css(...a)}
      }
    `,
    lg: (...a) => css`
      @media only screen and (min-width: ${mq.lg}) {
        ${css(...a)}
      }
    `,
    xl: (...a) => css`
      @media only screen and (min-width: ${mq.xl}) {
        ${css(...a)}
      }
    `
  }