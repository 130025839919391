import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import OgImage from '../../assets/og-image.png'

// TODO worth doing a graphql call here to get the resources for openGraph?
// TODO Remove unnecessary fonts and assets
// TODO Remove font-awesome and replace with Radix icons

const Head = ({ title, lottie }) => {
    const siteMetaObject = useStaticQuery(graphql`
    query{
      site{
        siteMetadata{
          description
          url
        }
      }
    }
  `)
    return(
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={siteMetaObject.site.siteMetadata.description} />
          
          <meta property="og:url" content="https://www.skepticacid.io/" />
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={siteMetaObject.site.siteMetadata.description}/>
          <meta property="og:image" content={OgImage} />

          <meta name="twitter:card" content={OgImage} />
          <meta property="twitter:domain" content={siteMetaObject.site.siteMetadata.url} />
          <meta property="twitter:url" content="https://www.skepticacid.io/" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={siteMetaObject.site.siteMetadata.description} />
          <meta name="twitter:image" content={OgImage} />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

          <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" /> 
          <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />  
          <script src="https://kit.fontawesome.com/58f18a61e9.js" crossorigin="anonymous"></script>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        </Helmet>
    )
}

  // <Helmet>
  //   <html lang="en" amp />
  //   <title>{title}</title>
  //   
  //   <meta property="og:title" content={title}/>
  //   <meta property="og:description" content={description}/>
  //   <meta property="og:image" content={image} />
  // </Helmet>



export default Head